<template>
  <div class="flex w-full flex-col gap-2 border-b border-grid px-4" @submit.prevent="testWebhook">
    <div :class="[modal ? 'flex-col' : '', 'flex gap-4 pb-10 pt-2 text-xs font-medium text-gray-300']">
      <div class="flex flex-1 flex-col gap-1">
        <label class="flex flex-1 flex-row items-center gap-1 text-xs font-medium text-gray-300">
          Name Delivery Option
          <span v-if="errorMessageName" class="pl-2 text-red-500">{{ errorMessageName }}</span>
        </label>
        <BaseTextbox v-model="webhook.name" :placeholder="titleize(webhook.channel)" />
      </div>
      <div class="flex flex-1 flex-col gap-1">
        <label class="flex flex-1 flex-row items-center gap-1 text-xs font-medium text-gray-300">
          {{ webhookTypeLabel }}
          <span v-if="errorMessageLink" class="pl-2 text-red-500">{{ errorMessageLink }}</span>
        </label>
        <BaseTextbox
          v-model="webhook.link"
          :placeholder="webhookTypeLabel === 'Email' ? userEmail : webhookTypePlaceholder"
          :readonly="!editable"
          @change="webhook.value.status = 'not-verified'"
        />
      </div>

      <div :class="[modal ? 'self-start' : 'self-end', 'flex flex-row justify-between gap-4']">
        <BaseButton class="self-end text-sm font-medium" type="secondary" @press="testWebhook">
          Send test {{ webhook.channel === 'email' ? 'email' : 'message' }}
        </BaseButton>
        <div class="flex flex-row items-center gap-2">
          <div class="text-gray-400">Status</div>
          <div class="flex flex-1 items-center">
            <BaseLoader v-if="webhook.status == 'processing'" size="w-6 h-6" />
            <CheckCircleIcon v-else-if="webhook.status == 'verified'" class="h-6 w-6 text-green-500" />
            <XCircleIcon v-else-if="webhook.status == 'not-verified'" class="h-6 w-6 text-red-500" />
            <XCircleIcon v-else class="h-6 w-6 text-gray-600" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex w-full justify-between space-x-4 px-6 pt-6">
    <div>
      <BaseButton v-if="modal" type="secondary" @press="$emitter.$emit('cancel:destination')">
        <ArrowLeftIcon class="mr-1 h-4 w-4" /> Back
      </BaseButton>
    </div>
    <div class="flex items-center space-x-2">
      <BaseButton v-if="!modal" type="secondary" @press="$emitter.$emit('cancel:destination')">Cancel</BaseButton>
      <BaseButton @press="submitWebhook">
        {{ webhook.id > 0 ? 'Save' : modal ? 'Use Delivery Option' : 'Create' }}
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';
import { useStore } from 'vuex';
import { ref, computed, inject, watch, onMounted } from 'vue';
import { titleize } from '@/composeables/filters';
import { CheckCircleIcon, XCircleIcon, ArrowLeftIcon } from '@heroicons/vue/24/outline';

const $http = useHttp();
const $emitter = useEmitter();
const store = useStore();

const emits = defineEmits(['update:model-value']);
const props = defineProps({
  modelValue: { type: Object, required: true },
  edit: { type: Boolean, default: false },
  modal: { type: Boolean, default: false }
});

//INITIALIZATION
const editable = ref(true);
const userEmail = computed(() => store.getters.session.user.email);
const webhook = computed({
  get: () => props.modelValue,
  set: val => emits('update:model-value', val)
});
const webhookTypeLabel = computed(() => {
  return {
    discord: 'Link',
    slack: 'Link',
    telegram: 'Chat ID',
    email: 'Email',
    webhook: 'HTTP Webhook'
  }[webhook.value.channel];
});
const webhookTypePlaceholder = computed(() => {
  return {
    discord: 'https://discord...',
    slack: 'https://slack...',
    telegram: '-1001177842334',
    email: 'user@email.com',
    webhook: 'https://webhook...'
  }[webhook.value.channel];
});
watch([() => props.edit, () => props.modelValue.channel, () => props.modelValue.link], () => {
  if (props.edit && props.modelValue.channel == 'email' && props.modelValue.link == userEmail.value) {
    editable.value = false;
  }
});
onMounted(() => {
  if (props.edit && props.modelValue.channel == 'email' && props.modelValue.link == userEmail.value) {
    editable.value = false;
  }
});

//TEST AND SUBMIT
const errorMessageName = ref('');
const errorMessageLink = ref('');
const testWebhook = async () => {
  errorMessageName.value = '';
  errorMessageLink.value = '';
  let payload = { ...webhook.value };
  if (payload.name == '' || payload.link == '') {
    errorMessageName.value = payload.name == '' ? 'Required' : '';
    errorMessageLink.value = payload.link == '' ? 'Required' : '';
    return false;
  }
  webhook.value.status = 'processing';
  try {
    await $http.post('/alert_channel_tests', {
      channel: payload.link,
      alert_type: webhook.value.channel
    });
    webhook.value.status = 'verified';
    webhook.value.disabled = false;
    return true;
  } catch {
    webhook.value.status = 'not-verified';
    webhook.value.disabled = true;
    errorMessageLink.value = `Invalid ${webhook.value.channel}`;
    return false;
  }
};
const submitWebhook = async () => {
  try {
    if (webhook.value.status != 'verified') {
      await testWebhook();
    }

    if (webhook.value.status === 'verified') {
      if (webhook.value.id && webhook.value.id > 0) {
        await $http.patch(`/data/webhooks/${webhook.value.id}`, { webhook: webhook.value });
      } else {
        const res = await $http.post('/data/webhooks', { webhook: webhook.value });
        $emitter.$emit('webhook-added', res.data?.webhook?.id);
        $emitter.$emit('update:webhooks', res.data?.webhook?.id);
      }
      $emitter.$emit('cancel:destination');
      await getWebhooks();
    }
  } catch {
    webhook.value.status = 'not-verified';
    errorMessageLink.value = `Invalid ${webhook.value.channel}`;
    return false;
  }
};
const getWebhooks = inject('get-webhooks');
</script>
